import React, { useState } from 'react';
import Header from '../navbar/Header';
import Footer from '../navbar/Footer';
import { motion } from 'framer-motion';
import './WebApplication.css';
import { FaCode, FaPalette, FaMobile, FaRocket, FaShieldAlt, FaCogs, FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios';
import OrderForm from './OrderForm';
import OrderModal from './OrderModal';



const projects = [
  {
    id: 1,
    title: 'Vogelkop',
    description: 'An architecture company specializing in innovative and modern design solutions.',
    image: 'https://proarchitech.com/wp-content/uploads/2021/08/project-detail-17.jpg',
    link: 'https://vogelkoparchitects.com/',
  },
  {
    id: 2,
    title: 'Ardorbiomed',
    description: 'An advanced e-commerce platform for purchasing medicines and healthcare products online with ease and reliability.',
    image: 'https://ardorbiomed.com/static/media/banner1.41d4d81a591369581626.png',
    link: 'https://ardorbiomed.com/',
  },
  {
    id: 3,
    title: 'Dr Youth Billing Software',
    description: 'A beauty-related company providing innovative solutions for skincare and wellness businesses.',
    image: 'https://5.imimg.com/data5/SELLER/Default/2022/5/BX/SZ/LR/3506526/online-billing-software-500x500.png',
    link: 'https://dryouth.bwsoft.in/',
  },
  {
    id: 4,
    title: 'Infi Boutique',
    description: 'A female boutique website offering trendy and stylish fashion collections for women.',
    image: 'https://i.pinimg.com/736x/99/47/e8/9947e8a4766756a094316310cac5012c.jpg',
    link: 'https://infiboutique.in/',
  },
  {
    id: 5,
    title: 'Fresh Food',
    description: 'A user-friendly supermarket website that enables seamless online grocery shopping with fresh food delivery at your doorstep.',
    image: 'https://cdn.pixabay.com/photo/2016/03/02/20/13/grocery-1232944_1280.jpg',
    link: '#',
  },
  {
    id: 6,
    title: 'Saaskin',
    description: 'A medicine-selling company offering a wide range of pharmaceutical products online with fast and reliable delivery.',
    image: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTV43mFAfPKNU1weAxxKNPpqHlH3ym0ort2w&s',
    link: 'http://saaskin.com/',
  }
];

const features = [
  {
    icon: <FaCode />,
    title: "Custom Development",
    description: "Tailored web solutions built to match your specific business requirements"
  },
  {
    icon: <FaPalette />,
    title: "Modern Design",
    description: "Sleek, responsive interfaces that deliver exceptional user experiences"
  },
  {
    icon: <FaMobile />,
    title: "Mobile-First Approach",
    description: "Websites optimized for seamless performance across all devices"
  }
];

const whyChooseUs = [
  {
    icon: <FaRocket />,
    title: "Fast Performance",
    description: "Lightning-quick load times and smooth user interactions"
  },
  {
    icon: <FaShieldAlt />,
    title: "Secure & Reliable",
    description: "Built with the latest security measures to protect your data"
  },
  {
    icon: <FaCheckCircle />,
    title: "Quality Assured",
    description: "Rigorous testing and quality control processes"
  }
];

const WebApplication = () => {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [order, setOrder] = useState(null);

  const handleOrderCreated = (orderData) => {
    setOrder(orderData);

    // Make sure Razorpay is loaded
    if (!window.Razorpay) {
      console.error('Razorpay not loaded!');
      return;
    }

    const options = {
      key: "rzp_test_KhVE7vRYni8JF0",
      amount: orderData.amount * 100, // Razorpay expects amount in paise
      currency: orderData.currency,
      name: "Web Development Services",
      description: "Web Application Development Package",
      order_id: orderData.order_id,
      handler: async (response) => {
        try {
          const result = await axios.post('https://brandingworld.net/api/api/verify-payment/', {
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature
          });
          
          if (result.data.status === 'SUCCESS') {
            alert("Payment successful! Your web development journey begins now.");
          } else {
            alert(result.data.message);
          }
        } catch (error) {
          console.error('Payment verification failed:', error);
          alert("Payment verification failed. Please contact support.");
        }
      },
      prefill: {
        name: "",
        email: order?.email || "",
        contact: order?.mobile || ""
      },
      theme: {
        color: "#6c5ce7"
      }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  return (
    <div className="web-app-page">
      <Header />
      
      {/* Banner with Parallax Effect */}
      <motion.div 
        className="web-app-banner"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <motion.img
          src="assets/img/webbanner.jpg"
          alt="Web Development"
          className="web-app-banner-img"
          initial={{ scale: 1.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5 }}
        />
        <motion.div 
          className="web-app-banner-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <div className="web-app-banner-content">
            <motion.h1 
              className="web-app-banner-title mt-5"
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              Web Applications
            </motion.h1>
            <motion.p 
              className="web-app-banner-desc"
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 1 }}
            >
              we design and develop fast, secure, and scalable web applications
            </motion.p>
          </div>
        </motion.div>
      </motion.div>

      {/* Features Section */}
      <section className="web-app-features">
        <div className="container mx-auto px-4">
          <motion.div 
            className="web-app-section-header"
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h2 className="web-app-section-title">Our Web Development Features</h2>
            <p className="web-app-section-desc">Creating powerful, scalable web solutions for modern businesses</p>
          </motion.div>
          
          <motion.div 
            className="web-app-features-grid"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            {features.map((feature, index) => (
              <motion.div
                key={index}
                className="web-app-feature-card"
                variants={fadeInUp}
                whileHover={{ y: -10 }}
              >
                <div className="web-app-feature-icon">{feature.icon}</div>
                <h3 className="web-app-feature-title">{feature.title}</h3>
                <p className="web-app-feature-desc">{feature.description}</p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* Projects Section */}
      <section className="web-app-projects">
        <div className="container mx-auto px-4">
          <motion.div 
            className="web-app-section-header"
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h2 className="web-app-section-title">Our Web Portfolio</h2>
            <p className="web-app-section-desc">Explore our successful web development projects</p>
          </motion.div>

          <motion.div 
            className="web-app-projects-grid"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            {projects.map((project) => (
              <motion.div
                key={project.id}
                className="web-app-project-card"
                variants={fadeInUp}
                whileHover={{ y: -10, scale: 1.02 }}
              >
                <div className="web-app-project-img-container">
                  <img 
                    src={project.image} 
                    alt={project.title} 
                    className="web-app-project-img"
                  />
                </div>
                <div className="web-app-project-content">
                  <h3 className="web-app-project-title">{project.title}</h3>
                  <p className="web-app-project-desc">{project.description}</p>
                  <Link to={project.link} 
    target="_blank" 
    ><motion.button
                    className="web-app-project-btn"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    View Project
                  </motion.button></Link>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="web-app-why-choose">
        <div className="container mx-auto px-4">
          <motion.div 
            className="web-app-section-header"
            variants={fadeInUp}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            <h2 className="web-app-section-title">Why Choose Us?</h2>
            <p className="web-app-section-desc">Expert web development with proven success</p>
          </motion.div>

          <motion.div 
            className="web-app-why-choose-grid"
            variants={staggerContainer}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
          >
            {whyChooseUs.map((item, index) => (
              <motion.div
                key={index}
                className="web-app-why-choose-card"
                variants={fadeInUp}
                whileHover={{ y: -10 }}
              >
                <div className="web-app-feature-icon">{item.icon}</div>
                <h3 className="web-app-feature-title">{item.title}</h3>
                <p className="web-app-feature-desc">{item.description}</p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </section>

      {/* CTA Section */}
      <motion.section 
        className="web-app-cta"
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
      >
        <div className="web-app-cta-content">
          <motion.h2 
            className="web-app-cta-title"
            initial={{ y: 30, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
          >
            Ready to Start Your Web Project?
          </motion.h2>
          <motion.p 
            className="web-app-cta-desc"
            initial={{ y: 30, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            Let's transform your ideas into powerful web applications
          </motion.p>
          <motion.button
            className="web-app-cta-btn"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setIsModalOpen(true)}
          >
            Get Started Today
          </motion.button>
          <OrderModal
    isOpen={isModalOpen}
    onClose={() => setIsModalOpen(false)}
    onOrderCreated={handleOrderCreated}
  />
        </div>
      </motion.section>


      <Footer />
    </div>
  );
};

export default WebApplication;