import React, { useEffect } from "react";
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import './Aboutus.css';

const fadeIn = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.6 }
};

const staggerChildren = {
  animate: {
    transition: {
      staggerChildren: 0.2
    }
  }
};

const Whowe = () => {
  useEffect(() => {
    document.title = "About Us | Branding World";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "A world of talent at your fingertips we are dedicated to providing top-notch It solutions and services to help businesses"
      );
  }, []);

  const expertiseItems = [
    "Expert Software Development",
    "Engaging Workshops, Training & Placement",
    "Cutting-edge Solutions for Your Needs"
  ];

  const whyChooseUs = [
    "Passionate Blended Team of IT & Academic Professionals",
    "Tailored Solutions Just for You",
    "To Be Your One-Stop Solution Provider"
  ];

  const aboutCards = [
    {
      icon: "assets/img/about/about-icon3.png",
      title: "Who We Are",
      description: "A Network Of Intellectuals - Your Partner for Digital Success"
    },
    {
      icon: "assets/img/about/about-icon4.png",
      title: "Our Vision",
      description: "Simplifying IT for Success in the Digital Age for both Corporates & Educational Institutions"
    },
    {
      icon: "assets/img/about/about-icon5.png",
      title: "Comprehensive Solutions",
      description: "Partnered with Industry Professionals for Your Success"
    }
  ];

  return (
    <div className="about-page">
      <Header />
      
      <motion.div 
        className="web-app-banner"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <motion.img
          src="assets/img/about.png"
          alt="Web Development"
          className="web-app-banner-img"
          initial={{ scale: 1.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5 }}
        />
        <motion.div 
          className="web-app-banner-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <div className="web-app-banner-content mt-5">
            <motion.h1 
              className="web-app-banner-title"
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              About us
            </motion.h1>
            <motion.p 
              className="web-app-banner-desc"
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 1 }}
            >
              Building the future 
            </motion.p>
          </div>
        </motion.div>
      </motion.div>

      {/* Welcome Section */}
      <motion.section 
        className="about-welcome-section"
        variants={staggerChildren}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
      >
        <div className="container">
          <div className="row">
            <motion.div 
              className="col-lg-6"
              variants={fadeIn}
            >
              <div className="about-welcome-content">
                <motion.h2 variants={fadeIn}>Welcome to Branding World!</motion.h2>
                <motion.h1 variants={fadeIn}><strong>A world of talent at your fingertips</strong></motion.h1>
                <motion.p variants={fadeIn}>
                  At Branding World, we are dedicated to providing top-notch IT solutions and services to help businesses thrive in the digital age. We understand the ever-evolving landscape of technology and the challenges faced by organizations in harnessing its full potential.
                </motion.p>
                <motion.div 
                  className="read-more"
                  variants={fadeIn}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Link to="/Mobile" className="about-btn-read-more">
                    <span>Know More</span>
                    <i className="bi bi-arrow-right"></i>
                  </Link>
                </motion.div>
              </div>
            </motion.div>
            <motion.div 
              className="col-lg-6"
              variants={fadeIn}
            >
              <motion.img 
                src="assets/img/about.jpg" 
                alt="About" 
                className="about-welcome-image "
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
            </motion.div>
          </div>
        </div>
      </motion.section>

      {/* Features Section */}
      <motion.section 
        className="about-features-section"
        variants={staggerChildren}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
      >
        <div className="container">
          <motion.div className="about-features-grid">
            {aboutCards.map((card, index) => (
              <motion.div 
                key={index}
                className="about-feature-card"
                variants={fadeIn}
                whileHover={{ y: -10, transition: { duration: 0.3 } }}
              >
                <motion.img 
                  src={card.icon} 
                  alt={card.title}
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                />
                <h3>{card.title}</h3>
                <p>{card.description}</p>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </motion.section>

      {/* Expertise Section */}
      <motion.section 
        className="about-expertise-section"
        variants={staggerChildren}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
      >
        <div className="container">
          <div className="row">
            <motion.div 
              className="col-lg-6"
              variants={fadeIn}
            >
              <div className="about-expertise-content">
                <h2>Our Expertise</h2>
                {expertiseItems.map((item, index) => (
                  <motion.div 
                    key={index}
                    className="about-expertise-item"
                    variants={fadeIn}
                    whileHover={{ x: 10 }}
                  >
                    <p>{item}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>
            <motion.div 
              className="col-lg-6"
              variants={fadeIn}
            >
              <div className="about-why-choose-content">
                <h2>Why Choose Us</h2>
                {whyChooseUs.map((item, index) => (
                  <motion.div 
                    key={index}
                    className="about-why-choose-item"
                    variants={fadeIn}
                    whileHover={{ x: 10 }}
                  >
                    <p>{item}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default Whowe;