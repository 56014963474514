import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from "react-router-dom";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import './Contact.css';

const ContactUs = () => {
  const [showAssistant, setShowAssistant] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    document.title = "ContactUs | Branding World";
    document.querySelector("meta[name='description']")
      .setAttribute("content", "Great IT field experience Innovative IT solutions to help your business contact now");
    
  
    setTimeout(() => setShowAssistant(true), 500);
    setTimeout(() => setShowMessage(true), 1500);
  }, []);

  const infoBoxes = [
    {
      icon: "bi-geo-alt",
      title: "Head Office",
      content: "Rathinam Complex, 2/12, Avenue Rd, Ponnangipuram, Nungambakkam, Chennai, Tamil Nadu-600034"
    },
    {
      icon: "bi-geo-alt",
      title: "Branch Office",
      content: "Rathinam Complex, 2/12, Avenue Rd, Ponnangipuram, Nungambakkam, Chennai, Tamil Nadu-600034"
    },
    {
      icon: "bi-telephone",
      title: "Call Us",
      content: <Link to="tel:+91 9087097555">+91 9087097555</Link>
    },
    {
      icon: "bi-envelope",
      title: "Email Us",
      content: <Link to="mailto:support@brandingworld.net">support@brandingworld.net</Link>
    }
  ];

  return (
    <div className="contact-page">
      <Header />
      <motion.div 
              className="web-app-banner"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
            >
              <motion.img
                src="../assets/img/contact.png"
                alt="Web Development"
                className="web-app-banner-img"
                initial={{ scale: 1.2 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1.5 }}
              />
              <motion.div 
                className="web-app-banner-overlay"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                <div className="web-app-banner-content">
                  <motion.h1 
                    className="web-app-banner-title mt-5"
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.8 }}
                  >
                    Contact us
                  </motion.h1>
                  <motion.p 
                    className="web-app-banner-desc"
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1 }}
                  >
                   We are just a message away. Let’s build something amazing together!
                  </motion.p>
                </div>
              </motion.div>
            </motion.div>
      
      
      {/* Hero Section with AI Assistant */}
      <motion.section 
        className="contact-heros"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
            <motion.div 
                      className="text-center"
                      initial={{ y: 50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 1 }}
                    >
                      <h2 className="floating-heading">Let's get start</h2>
                    </motion.div>

        <AnimatePresence>
          {showAssistant && (
            <motion.div
              className="ai-assistant"
              initial={{ x: '100%', opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: '100%', opacity: 0 }}
              transition={{ type: "spring", damping: 20 }}
            >
              <motion.img
                src="/assets/img/ai.svg"
                alt="AI Assistant"
                className="assistant-avatar"
                animate={{ rotate: [0, -5, 5, 0] }}
                transition={{ duration: 2, repeat: Infinity }}
              />
              {showMessage && (
                <motion.div
                  className="message-bubble"
                  initial={{ scale: 0, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 0.5 }}
                >
                  <p>👋 Hello! How can I help you today?</p>
                </motion.div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </motion.section>

      {/* Contact Section */}
      <motion.section 
        className="contact-section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.8 }}
      >
        <div className="container">
          <div className="row">
            {/* Info Boxes */}
            <motion.div 
              className="col-lg-6"
              variants={{
                hidden: { opacity: 0 },
                show: {
                  opacity: 1,
                  transition: {
                    staggerChildren: 0.2
                  }
                }
              }}
              initial="hidden"
              animate="show"
            >
              <div className="info-boxes-grid">
                {infoBoxes.map((box, index) => (
                  <motion.div
                    key={index}
                    className="info-box"
                    variants={{
                      hidden: { opacity: 0, y: 20 },
                      show: { opacity: 1, y: 0 }
                    }}
                    whileHover={{ scale: 1.05 }}
                  >
                    <i className={`bi ${box.icon}`}></i>
                    <h3>{box.title}</h3>
                    <p>{box.content}</p>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            {/* Contact Form */}
            <motion.div 
              className="col-lg-6"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 1 }}
            >
              <motion.form 
                className="contact-form"
                whileHover={{ boxShadow: "0 8px 30px rgba(0,0,0,0.1)" }}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <motion.input
                      whileFocus={{ scale: 1.02 }}
                      type="text"
                      placeholder="Your Name"
                      required
                    />
                  </div>
                  <div className="col-md-6">
                    <motion.input
                      whileFocus={{ scale: 1.02 }}
                      type="email"
                      placeholder="Your Email"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <motion.input
                      whileFocus={{ scale: 1.02 }}
                      type="text"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="col-12">
                    <motion.textarea
                      whileFocus={{ scale: 1.02 }}
                      rows="6"
                      placeholder="Message"
                      required
                    ></motion.textarea>
                  </div>
                  <div className="col-12 text-center">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      type="submit"
                    >
                      Send Message
                    </motion.button>
                  </div>
                </div>
              </motion.form>
            </motion.div>
          </div>
        </div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default ContactUs;