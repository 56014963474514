import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import './Clients.css';


const ClientRelationshipSection = () => {
  const controls = useAnimation();
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef, { once: true, amount: 0.3 });
  
  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  }, [isInView, controls]);
  
  const relationshipItems = [
    {
      id: 1,
      icon: 'fa-handshake',
      title: 'Strategic Partnership',
      description: 'We don\'t just serve clients—we build lasting partnerships focused on mutual growth and success.',
      color: '#4e73df'
    },
    {
      id: 2,
      icon: 'fa-lightbulb',
      title: 'Innovative Solutions',
      description: 'Every client challenge is an opportunity to pioneer creative, tailored solutions that drive real results.',
      color: '#1cc88a'
    },
    {
      id: 3,
      icon: 'fa-clipboard-check',
      title: 'Transparent Process',
      description: 'Our collaborative approach ensures clients are informed and involved at every stage of the journey.',
      color: '#f6c23e'
    },
    {
      id: 4,
      icon: 'fa-chart-line',
      title: 'Measurable Impact',
      description: 'We focus on delivering quantifiable value that translates directly to our clients\' bottom line.',
      color: '#e74a3b'
    }
  ];

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 100
      }
    }
  };

  const pathVariants = {
    hidden: { pathLength: 0 },
    visible: {
      pathLength: 1,
      transition: {
        duration: 2,
        ease: "easeInOut"
      }
    }
  };

  return (
    <motion.section 
      ref={sectionRef}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="relationship-section"
    >
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="section-header text-center mb-5"
        >
          <h2 className="section-title">Our Client Relationship</h2>
          <div className="title-underline"></div>
          <p className="section-subtitle">
            What makes our partnerships exceptional
          </p>
        </motion.div>

        <div className="relationship-content">
          <motion.div 
            className="connection-visualization"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            <svg width="100%" height="100%" viewBox="0 0 400 400" className="connection-svg">
              <motion.circle
                cx="200"
                cy="200"
                r="150"
                stroke="#4e73df"
                strokeWidth="2"
                fill="none"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 2, ease: "easeInOut" }}
              />
              <motion.path
                d="M100,200 C100,150 150,100 200,100"
                stroke="#1cc88a"
                strokeWidth="3"
                fill="none"
                variants={pathVariants}
              />
              <motion.path
                d="M200,100 C250,100 300,150 300,200"
                stroke="#f6c23e"
                strokeWidth="3"
                fill="none"
                variants={pathVariants}
              />
              <motion.path
                d="M300,200 C300,250 250,300 200,300"
                stroke="#e74a3b"
                strokeWidth="3"
                fill="none"
                variants={pathVariants}
              />
              <motion.path
                d="M200,300 C150,300 100,250 100,200"
                stroke="#36b9cc"
                strokeWidth="3"
                fill="none"
                variants={pathVariants}
              />

              <motion.circle
                cx="200"
                cy="200"
                r="40"
                fill="#4e73df"
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5, delay: 2 }}
              />
              <text x="200" y="205" textAnchor="middle" fill="white" fontSize="12" fontWeight="bold">
                Partnership
              </text>
            </svg>
          </motion.div>

          <motion.div 
            className="relationship-cards"
            variants={containerVariants}
          >
            {relationshipItems.map((item) => (
              <motion.div
                key={item.id}
                className="relationship-card"
                variants={itemVariants}
                whileHover={{ 
                  scale: 1.05,
                  boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
                  transition: { duration: 0.3 }
                }}
              >
                <div className="card-icon-wrapper" style={{ backgroundColor: item.color }}>
                  <i className={`fas ${item.icon}`}></i>
                </div>
                <h3 className="card-title">{item.title}</h3>
                <p className="card-description">{item.description}</p>
                <motion.div 
                  className="card-wave"
                  initial={{ scaleX: 0 }}
                  animate={{ scaleX: 1 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  style={{ backgroundColor: `${item.color}33` }}
                ></motion.div>
              </motion.div>
            ))}
          </motion.div>
        </div>

        <motion.div 
          className="testimonial-preview"
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 1 }}
        >
          <div className="quote-mark">
            <i className="fas fa-quote-left"></i>
          </div>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 1.2 }}
            className="testimonial-text"
          >
            "Their approach to partnership has transformed how we operate. We don't just view them as vendors; they're an extension of our team."
          </motion.p>
          <motion.div 
            className="testimonial-author"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.5, delay: 1.5 }}
          >
            <div className="author-avatar">
              <div className="avatar-placeholder"></div>
            </div>
            <div className="author-info">
              <h4>Client Success Story</h4>
              <p>Industry Leader</p>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default ClientRelationshipSection;