import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <>
      <header
        id="header" 
        className="header fixed-top"
        style={{ height: "80px", backgroundColor: "#fff"}}
      >
        <div
          className="container-fluid container-xl-header d-flex align-items-center justify-content-between"
          style={{ height: "80px" }}
        >
          <Link to="/" className="logo d-flex align-items-center">
            <img
              src="assets/img/logo/BW.png"
              alt=""
              style={{ height: "100%", width: "250px", objectFit: "cover" }}
            />
          </Link>

          <nav id="navbar" className="navbar thin-italic">
            <ul>
              <li>
                <Link to="/" className="nav-link scrollto active" style={{fontFamily:'sans-serif'}}>
                  Home
                </Link>
              </li>


              <li>
                <Link  className="nav-link scrollto" to="/Mobile" style={{fontFamily:'sans-serif'}}>
                  Mobile Application
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to="/Web-app" style={{fontFamily:'sans-serif'}}>
                  Website Designing
                </Link>
              </li>
              <li>
                <Link className="nav-link scrollto" to="/Seo" style={{fontFamily:'sans-serif'}}>
                  SEO
                </Link>
              </li>
              <li className="nav-link scrollto" >
                    <Link to="/Digital" style={{fontFamily:'sans-serif'}}>
                      <span>Digital Marketing</span>{" "}
                    </Link>
                  </li>

<li className="nav-link scrollto">
<Link to="/Clients" style={{fontFamily:'sans-serif'}}>Clients</Link>
                  </li>
              

              <li>
                <Link className="getstarted scrollto" to="/ContactUs">
                  Contact Us
                </Link>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle"></i>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
