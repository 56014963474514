import "./App.css";

import Header from "./components/navbar/Header";
import Footer from "./components/navbar/Footer";
import ContactUs from "./components/pages/ContactUs";
import Home from "./components/pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Whowe from "./components/pages/Whowe";
import Service from "./components/pages/Service";
import Clients from "./components/pages/Clients";
import Requestform from "./components/Businessapplication/Requestform";
import MobileApplication from "./components/pages/MobileApplication";
import WebApplication from "./components/pages/WebApplication";
import ScrollToTop from "./ScrollToTop";
import Seo from "./components/pages/Seo";
import DigitalMarketing from "./components/pages/DigitalMarketing";
import TechnologiesSection from "./components/pages/TechnologiesSection";
import OrderModal from "./components/pages/OrderModal";

function App() {
  return (
    <>
      <BrowserRouter>
      <ScrollToTop/>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/Header" element={<Header />}></Route>
          <Route path="/Web-app" element={<WebApplication />}></Route>

          <Route path="/Whowe" element={<Whowe />}></Route>
          <Route path="/Service" element={<Service />}></Route>
          <Route path="/Clients" element={<Clients />}></Route>
          <Route path="/Requestform" element={<Requestform />}></Route>
          <Route path="/ContactUs" element={<ContactUs />}></Route>
          <Route path="/Footer" element={<Footer />}></Route>
          <Route path="/Mobile" element={<MobileApplication />}></Route>
          <Route path="/Seo" element={<Seo/>}></Route>
          <Route path="/Digital" element={<DigitalMarketing/>}></Route>
          <Route path="/Technology" element={<TechnologiesSection />}></Route>
          <Route path="/Order" element={<OrderModal />}></Route>
          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
