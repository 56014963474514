import React from 'react';
import Header from '../navbar/Header';
import Footer from '../navbar/Footer';
import { motion } from 'framer-motion';
import { FaMobileAlt, FaRocket, FaCogs, FaUserFriends, FaShieldAlt, FaCode, FaCheckCircle } from 'react-icons/fa';
import './MobileApplication.css';

const projects = [
  {
    id: 1,
    title: 'RBS',
    description: 'A CCTV fixing and laptop service application that provides seamless support for security and IT maintenance.',
    image: '../assets/img/rbs.jpeg',
    link: '#',
  },
  {
    id: 2,
    title: 'DR YOUTH CRM',
    description: 'A powerful CRM application designed for managing customer relationships and streamlining healthcare services.',
    image: '../assets/img/crm.jpeg',
    link: '#',
  },
  {
    id: 3,
    title: 'FENICE SHIELD',
    description: 'A leading waterproofing solutions company that offers innovative protection against water damage for buildings and structures.',
    image: '../assets/img/fenice.jpeg',
    link: '#',
  },
  {
    id: 4,
    title: 'ARDOR BIOMED',
    description: 'An advanced e-commerce platform for purchasing medicines and healthcare products online with ease and reliability.',
    image: '../assets/img/ardor.jpeg',
    link: '#',
  },
  {
    id: 5,
    title: 'FRESH FOOD',
    description: 'A user-friendly supermarket app that enables seamless online grocery shopping with fresh food delivery at your doorstep.',
    image: 'https://img.freepik.com/premium-vector/fresh-food-vector-design-logo_518759-168.jpg?semt=ais_hybrid',
    link: '#',
  }
];

const features = [
  {
    icon: <FaRocket />,
    title: "Fast Development",
    description: "Quick turnaround time with efficient development processes"
  },
  {
    icon: <FaCogs />,
    title: "Custom Solutions",
    description: "Tailored mobile applications that match your specific needs"
  },
  {
    icon: <FaUserFriends />,
    title: "User-Centric Design",
    description: "Intuitive interfaces focused on user experience"
  }
];

const whyChooseUs = [
  {
    icon: <FaShieldAlt />,
    title: "Secure Development",
    description: "Built-in security measures to protect your data and users"
  },
  {
    icon: <FaCode />,
    title: "Clean Code",
    description: "Maintainable and scalable code architecture"
  },
  {
    icon: <FaCheckCircle />,
    title: "Quality Assured",
    description: "Rigorous testing and quality control processes"
  }
];

const MobileApplication = () => {
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8 } }
  };

  return (
    <div className="mobile-application-page">
      <Header />
      
      {/* Hero Section */}
      <motion.div 
              className="mobile-app-banner"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
            >
              <motion.img
                src="assets/img/mobilebanner.jpg"
                alt="Web Development"
                className="mobile-app-banner-img"
                initial={{ scale: 1.2 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1.5 }}
              />
              <motion.div 
                className="mobile-app-banner-overlay"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                <div className="mobile-app-banner-content mt-5">
                  <motion.h1 
                    className="mobile-app-banner-title"
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.8 }}
                  >
                    Mobile Applications
                  </motion.h1>
                  <motion.p 
                    className="mobile-app-banner-desc"
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1 }}
                  >
                    Explore our innovative mobile solutions designed for various industries.
                  </motion.p>
                </div>
              </motion.div>
            </motion.div>



      {/* Features Section */}
      <section className="features-section">
        <div className="section-header">
          <h2>Our Mobile App Development Features</h2>
          <p>We create powerful, scalable, and user-friendly mobile applications</p>
        </div>
        <div className="features-grid">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="feature-card"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <div className="feature-icon">{feature.icon}</div>
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Projects Section */}
      <section className="projects-section">
        <div className="section-header">
          <h2>Our Mobile App Portfolio</h2>
          <p>Discover our successful mobile application projects</p>
        </div>
        <div className="projects-grid">
          {projects.map((project) => (
            <motion.div
              key={project.id}
              className="project-card"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              whileHover={{ y: -10 }}
            >
              <div className="project-image-container">
                <img src={project.image} alt={project.title} className="project-image" />
              </div>
              <div className="project-content">
                <h3>{project.title}</h3>
                <p>{project.description}</p>
                {/* <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="view-project-btn"
                >
                  View Project
                </motion.button> */}
              </div>
            </motion.div>
          ))}
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us-section">
        <div className="section-header">
          <h2>Why Choose Us?</h2>
          <p>Expert mobile app development with proven success</p>
        </div>
        <div className="why-choose-us-grid">
          {whyChooseUs.map((item, index) => (
            <motion.div
              key={index}
              className="why-choose-card"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
            >
              <div className="why-choose-icon">{item.icon}</div>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section className="cta-section">
        <motion.div
          className="cta-content"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <h2>Ready to Build Your Mobile App?</h2>
          <p>Let's turn your idea into a successful mobile application</p>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="cta-button"
          >
            Get Started
          </motion.button>
        </motion.div>
      </section>

      <Footer />
    </div>
  );
};

export default MobileApplication;