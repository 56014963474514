import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import './OrderModal.css';
import { FaTimes, FaRobot, FaSpinner } from 'react-icons/fa';

const OrderModal = ({ isOpen, onClose, onOrderCreated }) => {
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [botMessage, setBotMessage] = useState('');
  const [error, setError] = useState('');
  
  const defaultAmount = 9999; // Default amount in INR

  // Bot typing animation effect
  useEffect(() => {
    if (isOpen) {
      const messages = [
        "Hello! I'm excited to help you start your journey.",
        "Just one step away from creating something amazing!",
        "Let's make your web project a reality today!"
      ];
  
      let messageIndex = 0;
      let charIndex = 0;
      let currentMsg = '';
  
      const typeWriter = () => {
        if (messageIndex < messages.length) {
          if (charIndex < messages[messageIndex].length) {
            currentMsg += messages[messageIndex].charAt(charIndex);
            setBotMessage(currentMsg);
            charIndex++;
            setTimeout(typeWriter, 50);
          } else {
            setTimeout(() => {
              currentMsg = '';
              charIndex = 0;
              messageIndex++;
              setTimeout(typeWriter, 1000); 
            }, 1000); 
          }
        }
      };
  
      typeWriter();
  
      return () => clearTimeout(typeWriter);
    }
  }, [isOpen]);
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Form validation
    if (!email || !mobile) {
      setError('Please fill in all fields');
      return;
    }
    
    if (!/^\d{10}$/.test(mobile)) {
      setError('Please enter a valid 10-digit mobile number');
      return;
    }
    
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError('Please enter a valid email address');
      return;
    }
    
    setError('');
    setLoading(true);
    
    try {
      const response = await axios.post('https://brandingworld.net/api/api/create-order/', {
        mobile,
        email,
        amount: defaultAmount
      });
      
      setLoading(false);
      onOrderCreated(response.data);
      setTimeout(() => onClose(), 500);
    } catch (error) {
      setLoading(false);
      setError('Something went wrong. Please try again.');
      console.error('Order creation failed:', error);
    }
  };

  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
      y: 50
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 25
      }
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      y: 50,
      transition: {
        duration: 0.3
      }
    }
  };

  const botVariants = {
    initial: { scale: 0 },
    animate: { 
      scale: 1,
      rotate: [0, 10, -10, 0],
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: 'reverse',
        repeatDelay: 3
      }
    }
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 0.3 }
    },
    exit: { 
      opacity: 0,
      transition: { duration: 0.3 }
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div 
            className="order-modal-overlay"
            variants={overlayVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            onClick={onClose}
          />
          
          <motion.div
            className="order-modal-container"
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <motion.button 
              className="order-modal-close"
              whileHover={{ scale: 1.2, rotate: 90 }}
              whileTap={{ scale: 0.9 }}
              onClick={onClose}
            >
              <FaTimes />
            </motion.button>
            
            <div className="order-modal-content">
              <div className="order-modal-left">
                <motion.div
                  className="order-modal-bot-container"
                  variants={botVariants}
                  initial="initial"
                  animate="animate"
                >
                  <div className="order-modal-bot">
                    <FaRobot className="order-modal-bot-icon" />
                    <div className="order-modal-bot-eyes"></div>
                  </div>
                </motion.div>
                
                <motion.div 
                  className="order-modal-message"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <p className="order-modal-typing">{botMessage}</p>
                </motion.div>
              </div>
              
              <div className="order-modal-right">
                <motion.h2 
                  className="order-modal-title"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                >
                  Just One Click To Start Your Journey
                </motion.h2>
                
                <motion.div 
                  className="order-modal-pricing"
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.2 }}
                >
                  <span className="order-modal-price">₹{defaultAmount}</span>
                  <span className="order-modal-price-text">Special Package</span>
                  <span className="order-modal-price-text">Website + Domain + server</span>
                </motion.div>
                
                <form onSubmit={handleSubmit} className="order-modal-form">
                  {error && <div className="order-modal-error">{error}</div>}
                  
                  <motion.div 
                    className="order-modal-form-group"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.3 }}
                  >
                    <label htmlFor="mobile">Mobile Number</label>
                    <input
                      type="tel"
                      id="mobile"
                      placeholder="Enter your 10-digit mobile"
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value.replace(/\D/g, '').slice(0, 10))}
                    />
                  </motion.div>
                  
                  <motion.div 
                    className="order-modal-form-group"
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: 0.4 }}
                  >
                    <label htmlFor="email">Email Address</label>
                    <input
                      type="email"
                      id="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </motion.div>
                  
                  <motion.button
                    type="submit"
                    className="order-modal-submit"
                    whileHover={{ scale: 1.05, boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)" }}
                    whileTap={{ scale: 0.95 }}
                    disabled={loading}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: 0.5 }}
                  >
                    {loading ? (
                      <><FaSpinner className="order-modal-spinner" /> Processing...</>
                    ) : (
                      "Proceed to Payment"
                    )}
                  </motion.button>
                </form>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default OrderModal;