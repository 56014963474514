import React from 'react';
import { motion } from 'framer-motion';
import Header from '../navbar/Header';
import Footer from '../navbar/Footer';
import { FaLink, FaFileAlt, FaPenNib, FaTools, FaChartLine, FaCheckCircle, FaShieldAlt, FaHeadset } from 'react-icons/fa';

const Seo = () => {
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  const services = [
    { icon: <FaLink />, title: "Link Building" },
    { icon: <FaFileAlt />, title: "On-Page SEO" },
    { icon: <FaPenNib />, title: "Content Strategy" },
    { icon: <FaTools />, title: "Technical Audits" }
  ];

  const whyChooseUs = [
    {
      icon: <FaChartLine className="text-blue-600 text-4xl" />,
      title: "High-End Analyzing",
      description: "We develop projects as per our client needs. We analyze our customer requirements to the core to design a structured format, which helps us to deliver our fantastic services conveniently."
    },
    {
      icon: <FaShieldAlt className="text-blue-600 text-4xl" />,
      title: "Excellence Track Record",
      description: "We have a special team to track all the records of every individual project. The tracked records are converted as a database for the brief analysis. This process helps your business to take into the next level."
    },
    {
      icon: <FaHeadset className="text-blue-600 text-4xl" />,
      title: "Our Dedicated Support",
      description: "You can call us at any time to fix all your issues easily. Our support team will give you a complete technical support. Reach us @ 9962083444 to get quick support. We are here to serve you..!!"
    }
  ];
  

  return (
    <>
    <motion.section initial="hidden" animate="visible" variants={fadeInUp} className="seo-section py-5">
    <Header />
    <motion.div 
        className="web-app-banner"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      >
        <motion.img
          src="assets/img/seo.png"
          alt="Web Development"
          className="web-app-banner-img"
          initial={{ scale: 1.2 }}
          animate={{ scale: 1 }}
          transition={{ duration: 1.5 }}
        />
        <motion.div 
          className="web-app-banner-overlay"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          <div className="web-app-banner-content">
            <motion.h1 
              className="web-app-banner-title mt-5"
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              SEO
            </motion.h1>
            <motion.p 
              className="web-app-banner-desc"
              initial={{ y: 30, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 1 }}
            >
             Experience quality, reliability, and customer satisfaction with us.
            </motion.p>
          </div>
        </motion.div>
      </motion.div>
      <motion.div 
              className="web-hero-section mt-3"
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <h2 className="floating-heading">Search engine Optimization</h2>
            </motion.div>
      <div className="container">
        <div className="row align-items-center">
          {/* Left Side: SEO Description */}
          <motion.div variants={fadeInUp} className="col-lg-6 col-md-12 mb-4 mb-lg-0">
            <h3 className="section-subtitle">Why SEO Matters</h3>
            <h1 className="section-title">Boost Your Online Presence with SEO</h1>
            <p className="section-text">SEO enhances visibility and drives organic traffic. Our services include:</p>
            <ul style={{listStyleType:'none'}}>
              <li><FaCheckCircle className="text-success me-2" />Keyword Research & Strategy</li>
              <li><FaCheckCircle className="text-success me-2" />On-Page and Off-Page Optimization</li>
              <li><FaCheckCircle className="text-success me-2" />Content Marketing & Link Building</li>
              <li><FaCheckCircle className="text-success me-2" />Technical SEO & Site Audits</li>
              <li><FaCheckCircle className="text-success me-2" />Local SEO & Analytics</li>
            </ul>
          </motion.div>

          {/* Right Side: Image with Floating Effect */}
          <motion.div animate={{ y: [0, -15, 0] }} transition={{ duration: 3, repeat: Infinity, ease: 'easeInOut' }} className="col-lg-6 col-md-12 text-center">
            <img src="../assets/img/info-1.png" alt="SEO Illustration" className="img-fluid rounded" />
          </motion.div>
        </div>
        </div>
        </motion.section>

        {/* Additional Section with New Styles */}
        <div className="new-section text-center py-5">
          <h2 className="section-title">Our Core SEO Services</h2>
          <div className="row">
            {services.map((service, index) => (
              <motion.div key={index} variants={fadeInUp} className="col-md-3 col-sm-6 mb-4">
                <div className="service-card custom-shadow rounded p-3 text-center">
                  <div className="service-icon mb-2 text-primary" style={{ fontSize: '2.5rem' }}>{service.icon}</div>
                  <p className="service-title mb-0 font-weight-bold"style={{ color:'black ' }} >{service.title}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        <div className="container why-choose-us py-5">
            <div className="text-center mb-5">
              <h1 className="section-title">Why Choose Us?</h1>
            </div>
            <div className="row">
              {whyChooseUs.map((item, index) => (
                <motion.div 
                  key={index}
                  variants={fadeInUp}
                  className="col-lg-4 col-md-6 mb-4"
                >
                  <div className="choose-us-card p-4 h-full bg-white rounded-lg shadow-lg text-center">
                    <div className="icon-container mb-4">
                      {item.icon}
                    </div>
                    <h3 className="text-xl font-bold mb-3 text-gray-800">{item.title}</h3>
                    <p className="text-gray-600 leading-relaxed">{item.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>

    <Footer />
    </>
  );
};

export default Seo;
