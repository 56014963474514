import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import './Clients.css'; // We'll create this CSS below
import ClientRelationshipSection from './ClientRelationshipSection';

const Clients = () => {
  const [selectedFilter, setSelectedFilter] = useState('all');
  
  const categories = [
    { id: 'all', name: 'All' },
    { id: 'filter-ecom', name: 'E-commerce' },
    { id: 'filter-manufacturing', name: 'Service' },
    { id: 'filter-retail', name: 'Medicine' },
    { id: 'filter-trade', name: 'Business' },
    { id: 'filter-data', name: 'Others' }
  ];

  const clients = [
    { id: 1, category: 'filter-ecom', image: 'https://img.freepik.com/free-vector/blue-infinity-symbol_1284-43066.jpg?t=st=1739519283~exp=1739522883~hmac=c34e542be945070f6022114d76e3f46c90d88056f6fee1694318e9ec435e42fc&w=740', hasBorder: true },
    { id: 2, category: 'filter-ecom', image: 'assets/img/clients/vtradelogo.png', hasBorder: false },
    { id: 3, category: 'filter-manufacturing', image: 'assets/img/clients/fenice-shield.jpg', hasBorder: false },
    { id: 4, category: 'filter-trade', image: 'assets/img/clients/vogelkop.png', hasBorder: true },
    { id: 5, category: 'filter-trade', image: 'assets/img/clients/10pl_logo.png', hasBorder: false },
    { id: 6, category: 'filter-trade', image: 'assets/img/clients/live-wire-logo.png', hasBorder: true },
    { id: 7, category: 'filter-retail', image: 'assets/img/clients/saaskin.png', hasBorder: true },
    { id: 8, category: 'filter-data', image: 'assets/img/clients/ckkids-logo.png', hasBorder: true },
    { id: 9, category: 'filter-data', image: 'assets/img/clients/petromann-logo.jpg', hasBorder: false },


    { id: 10, category: 'filter-ecom', image: 'assets/img/clients/orange.jpg', hasBorder: true },
    { id: 11, category: 'filter-trade', image: 'assets/img/clients/Sherif.png', hasBorder: true },
    { id: 12, category: 'filter-manufacturing', image: 'assets/img/clients/dryouth.png', hasBorder: true },
    { id: 13, category: 'filter-manufacturing', image: 'assets/img/clients/cnd.png', hasBorder: false },
    { id: 14, category: 'filter-trade', image: 'assets/img/clients/youthimport.png', hasBorder: false },
    { id: 15, category: 'filter-trade', image: 'assets/img/clients/german.png', hasBorder: false },
    { id: 16, category: 'filter-trade', image: 'assets/img/clients/marcinas.png', hasBorder: false },
    { id: 17, category: 'filter-manufacturing', image: 'assets/img/clients/weplay.png', hasBorder: false },
    { id: 18, category: 'filter-manufacturing', image: 'assets/img/clients/sathaya.png', hasBorder: false },
  ];

  const filteredClients = selectedFilter === 'all' 
    ? clients 
    : clients.filter(client => client.category === selectedFilter);

  return (
    <div className="clients-page">
      <Header />
      <motion.div 
              className="web-app-banner"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
            >
              <motion.img
                src="assets/img/client.png"
                alt="Web Development"
                className="web-app-banner-img"
                initial={{ scale: 1.2 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1.5 }}
              />
              <motion.div 
                className="web-app-banner-overlay"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                <div className="web-app-banner-content">
                  <motion.h1 
                    className="web-app-banner-title mt-5"
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.8 }}
                  >
                    Our clients
                  </motion.h1>
                  <motion.p 
                    className="web-app-banner-desc"
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1 }}
                  >
                     we are proud to partner with a diverse range of clients from various industries.
                  </motion.p>
                </div>
              </motion.div>
            </motion.div>

            <ClientRelationshipSection />

            
      <motion.section 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
        className="portfolio-section"
      >
        <div className="container">
          

          <motion.div 
            className="filter-container"
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <ul className="filter-list">
              {categories.map((category) => (
                <motion.li
                  key={category.id}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <button
                    onClick={() => setSelectedFilter(category.id)}
                    className={`filter-button ${selectedFilter === category.id ? 'active' : ''}`}
                  >
                    {category.name}
                  </button>
                </motion.li>
              ))}
            </ul>
          </motion.div>

          <motion.div 
            layout
            className="clients-grid"
          >
            <AnimatePresence>
              {filteredClients.map((client) => (
                <motion.div
                  key={client.id}
                  layout
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  transition={{ duration: 0.4 }}
                  whileHover={{ scale: 1.05 }}
                  className="client-card-wrapper"
                >
                  <div className={`client-card ${client.hasBorder ? 'with-border' : ''}`}>
                    <motion.img
                      src={client.image}
                      alt="Client logo"
                      loading="lazy"
                      whileHover={{ scale: 1.1 }}
                      transition={{ duration: 0.3 }}
                    />
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        </div>
      </motion.section>
      
      <Footer />
    </div>
  );
};

export default Clients;