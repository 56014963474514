import React, { useEffect,useState } from "react";
import axios from 'axios';
import { motion } from "framer-motion";
import Header from "../navbar/Header";
import Footer from "../navbar/Footer";
import { Link } from "react-router-dom";
import TechnologiesSection from "./TechnologiesSection";
import OrderModal from "./OrderModal";


const Home = () => {
  useEffect(() => {
    document.title = "Home | Branding World";
    document
      .querySelector("meta[name='description']")
      .setAttribute(
        "content",
        "Great IT field experience Innovative IT solutions to help your business"
      );
  }, []);

  // Animation variants
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6 }
    }
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const scaleIn = {
    hidden: { scale: 0.8, opacity: 0 },
    visible: {
      scale: 1,
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  const slideIn = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: { duration: 0.8 }
    }
  };

  const apps = [
    {
      img: 'assets/img/img-1.avif',
      title: 'E-commerce app',
    },
    {
      img: 'assets/img/img-2.jpg',
      title: 'Billing-software',
    },
    {
      img: 'assets/img/travel.jpeg',
      title: 'MechUni - Online Mechanical Services',
    },
    {
      img: 'https://img.freepik.com/free-vector/isometric-crm-illustration_52683-83950.jpg?t=st=1739524993~exp=1739528593~hmac=14b3c5a1888021c9fe3aa860c21616610ff47d0bd3ed81a542a63c57c83d7848&w=900',
      title: 'CRM - application',
    },
    {
      img: 'assets/img/service1.jpg',
      title: 'Service apps',
    },
    {
      img: 'assets/img/dress.jpg',
      title: 'Fashion Apps',
    },
  ];

  const services = [
    {
      title: "Mobile app Development",
      icon: "assets/img/icon-5.png",
      color: "rgb(124, 65, 251)",
    },
    {
      title: "Web Development",
      icon: "assets/img/icon-6.png",
      color: "rgb(30, 200, 146)",
    },
    {
      title: "Digital Marketing",
      icon: "assets/img/icon-2.png",
      color: "rgb(255, 66, 104)",
    },
    {
      title: "SEO",
      icon: "assets/img/icon-3.png",
      color: "rgb(255, 187, 11)",
    },
  ];

  const values = [
    {
      icon: "assets/img/features-3.png",
      title: "Top Flexibility",
      description: "Our IT solutions are designed to be flexible and scalable, so you can easily adapt them to your changing needs.",
    },
    {
      icon: "assets/img/values-2.png",
      title: "Time Saving",
      description: "Our IT solutions can help you save time and money by automating tasks and streamlining processes.",
    },
    {
      icon: "assets/img/values-3.png",
      title: "Management",
      description: "Our IT solutions provide you with the tools you need to manage your IT infrastructure effectively.",
    },
    {
      icon: "assets/img/values-1.png",
      title: "Collaborative",
      description: "Our IT solutions are designed to promote collaboration and communication between employees.",
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [order, setOrder] = useState(null);

  const handleOrderCreated = (orderData) => {
    setOrder(orderData);

    // Make sure Razorpay is loaded
    if (!window.Razorpay) {
      console.error('Razorpay not loaded!');
      return;
    }

    const options = {
      key: "rzp_test_KhVE7vRYni8JF0",
      amount: orderData.amount * 100, // Razorpay expects amount in paise
      currency: orderData.currency,
      name: "Web Development Services",
      description: "Web Application Development Package",
      order_id: orderData.order_id,
      handler: async (response) => {
        try {
          const result = await axios.post('https://brandingworld.net/api/api/verify-payment/', {
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature
          });
          
          if (result.data.status === 'SUCCESS') {
            // Show success message
            alert("Payment successful! Your web development journey begins now.");
          } else {
            alert(result.data.message);
          }
        } catch (error) {
          console.error('Payment verification failed:', error);
          alert("Payment verification failed. Please contact support.");
        }
      },
      prefill: {
        name: "",
        email: order?.email || "",
        contact: order?.mobile || ""
      },
      theme: {
        color: "#6c5ce7"
      }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };


  return (
    <div>
      <Header />

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
        className="carousel slide"
        id="carouselExampleControls"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          <div className="carousel-item active">
            <motion.section
              initial={{ scale: 1.1 }}
              animate={{ scale: 1 }}
              transition={{ duration: 1.5 }}
              className="heroslider d-flex align-items-center"
               style={{
                backgroundImage:
                  "url('https://img.freepik.com/free-photo/medium-shot-bored-people-working_23-2150697600.jpg?t=st=1727346706~exp=1727350306~hmac=9da8f9eba2d9b618909dab8d5cea0e3b28585cd8cbe9ed66e81f47f3f094873f&w=740')",
              }}
              
            >
              <div className="container">
                <motion.div
                  initial={{ x: -100, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ delay: 0.5, duration: 0.8 }}
                  className="row"
                >
                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                    {/* <motion.h1
                      variants={fadeInUp}
                      className="hero-title"
                    >
                      Transform Your Business with Digital Innovation
                    </motion.h1> */}
                  </div>
                </motion.div>
              </div>
              <motion.button
      className="web-app-cta-btn floating-btn"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => setIsModalOpen(true)}
    >
      Book website
    </motion.button>
            </motion.section>
            </div>

          <div className="carousel-item">
            <section
              className="heroslider d-flex align-items-center"
              style={{
                backgroundImage:
                  "url('https://img.freepik.com/free-photo/group-young-business-people-working-office_158595-5210.jpg?t=st=1740742902~exp=1740746502~hmac=c2e2742bb3befdcf2304f551f223208715935e689a962e0a490b61af35a07001&w=1060')",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6"></div>

                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                  </div>
                </div>
              </div>
              <motion.button
      className="web-app-cta-btn floating-btn"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => setIsModalOpen(true)}
    >
      Book Website
    </motion.button>
            </section>
          </div>
          <div className="carousel-item">
            <section
              className="heroslider d-flex align-items-center"
              style={{
                backgroundImage:
                  "url('https://img.freepik.com/free-photo/modern-equipped-computer-lab_23-2149241213.jpg?t=st=1727347356~exp=1727350956~hmac=bc3a80697ccb4bd225b696cbdce725cec738940c03e41f8be7bf42f6ca068bc0&w=996 ')",
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-6"></div>

                  <div className="col-lg-6 d-flex flex-column justify-content-center">
                  </div>
                </div>
              </div>
              <motion.button
      className="web-app-cta-btn floating-btn"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => setIsModalOpen(true)}
    >
    Book Website
    </motion.button>
            </section>
          </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </motion.div>

      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerContainer}
        className="services-section"
      >
        <div className="container">
          <motion.header  className="section-header"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}>
            <motion.h3 variants={fadeInUp} className="section-title">
              Professional IT services for your business
            </motion.h3>
            <motion.p variants={fadeInUp} className="section-description">
              We provide a wide range of IT services that ensure your company's success.
            </motion.p>
          </motion.header>

          <motion.div variants={staggerContainer} className="services-grid">
            {services.map((service, index) => (
              <motion.div
                key={index}
                variants={scaleIn}
                whileHover={{ scale: 1.05 }}
                className="service-card"
                style={{ backgroundColor: service.color }}
              >
                <motion.div 
                  className="service-icon"
                  whileHover={{ rotate: 360 }}
                  transition={{ duration: 0.8 }}
                >
                  <img src={service.icon} alt={service.title} />
                </motion.div>
                <h4>{service.title}</h4>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </motion.section>

      {/* About Section */}
      <motion.section
  initial="hidden"
  whileInView="visible"
  viewport={{ once: true }}
  variants={staggerContainer}
  className="about-section"
  style={{padding:'0px'}}
>
  <div className="container mt-4">
    <div className="row align-items-center">
      {/* Left Side: Text Content */}
      <motion.div
        variants={slideIn}
        className="col-lg-6 col-md-12"
      >
        <motion.h3 variants={fadeInUp} className="section-subtitle">
          What Makes <b>Branding World</b> Stand Out
        </motion.h3>
        <motion.h1 variants={fadeInUp} className="section-title">
          Digital Technology, IT Solutions, and Service Providers Worldwide
        </motion.h1>
        <motion.p variants={fadeInUp} className="section-text">
          Bring win-win survival techniques to the table in order to assure dotted proactive control. 
          At the end of the day, moving ahead, there will be a new normal that has developed.
        </motion.p>
        <motion.div variants={fadeInUp} className="cta-button">
          <Link to="/About" className="btn-get-started">
            Learn More
            <i className="bi bi-arrow-right"></i>
          </Link>
        </motion.div>
      </motion.div>

      {/* Right Side: Image */}
      <motion.div 
  variants={fadeInUp} 
  className="col-lg-6 col-md-12 text-center"
>
  <motion.img 
    src="../assets/img/developer.jpg" 
    alt="About Branding World" 
    className="img-fluid rounded"
    animate={{ y: [0, -20, 0] }}
    transition={{ 
      duration: 3, 
      repeat: Infinity, 
      repeatType: "reverse", 
      ease: "easeInOut" 
    }}
  />
</motion.div>
    </div>
  </div>
</motion.section>

<TechnologiesSection />


      {/* Portfolio Section */}
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerContainer}
        className="portfolio-section"
      >
        <div className="container">
          <motion.h1 variants={fadeInUp} className="section-title text-center">
            Our Portfolio
          </motion.h1>
          <motion.p variants={fadeInUp} className="section-subtitle text-center">
            IMPROVE YOUR BUSINESS IDEAS WITH INNOVATION
          </motion.p>
          <motion.div variants={staggerContainer} className="portfolio-grid">
            {apps.map((app, index) => (
              <motion.div
                key={index}
                variants={scaleIn}
                whileHover={{ scale: 1.05 }}
                className="portfolio-card"
              >
                <motion.img
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                  src={app.img}
                  alt={app.title}
                  className="portfolio-image"
                />
                <h3 className="portfolio-title">{app.title}</h3>
              </motion.div>
            ))}
          </motion.div>
        </div>
      </motion.section>

      {/* Values Section */}
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerContainer}
        className="values-section"
      >
        <div className="container">
          <div className="row">
            {values.map((value, index) => (
              <motion.div
                key={index}
                variants={scaleIn}
                whileHover={{ scale: 1.05 }}
                className="col-lg-3 col-md-6 value-card-main"
              >
                <div className="value-card">
                  <motion.img
                    whileHover={{ rotate: 8 }}
                    transition={{ duration: 0.8 }}
                    src={value.icon}
                    alt={value.title}
                    className="value-icon"
                  />
                  <h3>{value.title}</h3>
                  <p>{value.description}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </motion.section>

      <OrderModal
    isOpen={isModalOpen}
    onClose={() => setIsModalOpen(false)}
    onOrderCreated={handleOrderCreated}
  />

      <Footer />
    </div>
  );
};

export default Home;
