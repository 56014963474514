import React from 'react';
import { motion } from 'framer-motion';
import Header from '../navbar/Header';
import Footer from '../navbar/Footer';
import './DigitalMarketing.css';

import { 
  FaSearch, 
  FaHashtag, 
  FaEnvelope, 
  FaMobileAlt,
  FaChartBar,
  FaBullseye,
  FaUsers,
  FaRocket
} from 'react-icons/fa';
import { Link } from 'react-router-dom';


const DigitalMarketing = () => {
  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.8, ease: 'easeOut' } },
  };

  const services = [
    {
      icon: <FaSearch />,
      title: "SEO Marketing",
      description: "Boost your website's visibility in search results and drive organic traffic."
    },
    {
      icon: <FaHashtag />,
      title: "Social Media",
      description: "Engage with your audience across popular social platforms and build brand awareness."
    },
    {
      icon: <FaEnvelope />,
      title: "Email Marketing",
      description: "Create targeted email campaigns that convert subscribers into customers."
    },
    {
      icon: <FaMobileAlt />,
      title: "Mobile Marketing",
      description: "Reach customers on their mobile devices with targeted campaigns and apps."
    }
  ];

  const benefits = [
    {
      icon: <FaChartBar />,
      title: "Increased ROI",
      description: "Track and measure results in real-time to optimize your marketing spend."
    },
    {
      icon: <FaBullseye />,
      title: "Targeted Reach",
      description: "Connect with your ideal audience through precise targeting options."
    },
    {
      icon: <FaUsers />,
      title: "Better Engagement",
      description: "Create meaningful interactions with your customers across channels."
    }
  ];

  return (
    <div className="digital-marketing">
      <Header />
      {/* <div className="banner">
        <img
          src="https://www.mygreatlearning.com/blog/wp-content/uploads/2022/06/digital-marketing-manager-1024x576.jpg"
          alt="cybersecurity"
          style={{
            height: "380px",
            width: "100%",
            objectFit: "cover",
          }}
        ></img>
      </div> */}

      <motion.div 
              className="web-app-banner"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
            >
              <motion.img
                src="https://www.mygreatlearning.com/blog/wp-content/uploads/2022/06/digital-marketing-manager-1024x576.jpg"
                alt="Web Development"
                className="web-app-banner-img"
                initial={{ scale: 1.2 }}
                animate={{ scale: 1 }}
                transition={{ duration: 1.5 }}
              />
              <motion.div 
                className="web-app-banner-overlay"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.5 }}
              >
                <div className="web-app-banner-content">
                  <motion.h1 
                    className="web-app-banner-title mt-5"
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.8 }}
                  >
                    Digital marketing
                  </motion.h1>
                  <motion.p 
                    className="web-app-banner-desc"
                    initial={{ y: 30, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 1 }}
                  >
                   Boost your online presence and drive results with our expert digital marketing solutions. 
                  </motion.p>
                </div>
              </motion.div>
            </motion.div>

           {/* Services Section */}
           <section className="services-section">
        <div className="section-header">
          <h2>Our Digital Marketing Services</h2>
          <p>Comprehensive digital marketing solutions tailored to your business needs</p>
        </div>
        <div className="services-grid">
          {services.map((service, index) => (
            <motion.div
              key={index}
              variants={fadeInUp}
              whileHover={{ y: -10 }}
              className="service-card"
            >
              <div className="service-icon">{service.icon}</div>
              <h3>{service.title}</h3>
              <p>{service.description}</p>
            </motion.div>
          ))}
        </div>
      </section>
      
      {/* Hero Section */}
      <section className="hero-section">
        <motion.div 
          initial="hidden"
          animate="visible"
          variants={fadeInUp}
          className="hero-content"
        >
          <div className="hero-text">
            <h1>Transform Your Digital Presence</h1>
            <p>Innovative digital marketing solutions that drive results and grow your business.</p>
            <motion.button 
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="cta-button"
            >
              Get Started
            </motion.button>
          </div>
          <div className="hero-image">
            <motion.img 
              src="assets/img/digi1.png" 
              alt="Digital Marketing"
              animate={{ y: [0, -20, 0] }}
              transition={{ duration: 4, repeat: Infinity, ease: "easeInOut" }}
            />
          </div>
        </motion.div>
      </section>

 

      {/* Benefits Section */}
      <section className="benefits-section">
        <div className="section-header">
          <h2>Why Digital Marketing Matters</h2>
          <p>Stay ahead of the competition with data-driven digital marketing strategies</p>
        </div>
        <div className="benefits-grid">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              variants={fadeInUp}
              whileHover={{ scale: 1.05 }}
              className="benefit-card"
            >
              <div className="benefit-icon">{benefit.icon}</div>
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </motion.div>
          ))}
        </div>
       <Link to='/ContactUs'> <motion.div 
          variants={fadeInUp}
          className="contact-banner"
        >
          <FaRocket className="banner-icon" />
          <span>Ready to boost your digital presence? Contact us today!</span>
        </motion.div></Link>
      </section>

      <Footer />
    </div>
  );
};

export default DigitalMarketing;